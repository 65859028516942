import React from 'react';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

function ContactFormDeComponent() {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <section className="relative text-white my-12">
      <form
        name="Kontakt"
        method="post"
        action="/danke"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="contact-form"
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Kontakt" />
        <p hidden>
          <label>
            Don’t fill this out:
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

        <div className="grid gap-2 md:grid-cols-2 md:gap-8">
          <div>
            <label htmlFor="first-name">Name*</label>
            <input className="block" autocomplete="name" placeholder="Max Mustermann" id="first-name" type="text" onChange={handleChange} name="firstName" required />
          </div>
          <div>
            <label htmlFor="email">E-Mail*</label>
            <input className="block" autocomplete="email" placeholder="mustermann@gmail.com" id="email" type="email" onChange={handleChange} name="email" required />
          </div>
          <div>
            <label htmlFor="phone">Telefon</label>
            <input className="block" autocomplete="tel" placeholder="+43 1234 56 789" id="phone" type="tel" onChange={handleChange} name="phone" />
          </div>
          <div>
            <label htmlFor="subject">Betreff*</label>
            <input className="block" autocomplete="name" placeholder="Meine Anfrage" id="subject" type="text" onChange={handleChange} name="subject" />
          </div>
          <div className="md:col-span-2">
            <label htmlFor="message">Ihre Anfrage*</label>
            <textarea className="form-textarea block w-full" id="message" placeholder="Bitte kontaktieren Sie mich." rows="3" onChange={handleChange} name="message" required />
          </div>
        </div>

        <div className="flex md:col-span-2">
          <label className="flex items-center text-sm md:text-base">
            <input type="checkbox" className="form-checkbox w-auto mt-0 cursor-pointer" required />
            <span className="ml-3 text-gray-400 text-xs my-8 cursor-pointer">
              Ich bin mit den&nbsp;
              <Link to="/datenschutz" title="Zu unseren Datenschutzrichtlinien" className="underline">
                Datenschutzrichtlinien
              </Link>
              &nbsp;einverstanden.
            </span>
          </label>
        </div>

        <button className="block px-6 py-2 mr-auto text-white rounded bg-secondary-font border border-transparent hover:bg-transparent hover:border-white">
          Absenden{' '}
          <svg x="0" y="0" viewBox="0 0 960 560" class="w-5 fill-current text-white inline -rotate-90">
            <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path>
          </svg>
        </button>
      </form>
    </section>
  );
}

export default ContactFormDeComponent;
