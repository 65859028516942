import React from 'react';
import ContactForm from '../components/ContactForm';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

const ContactPage = () => (
  <Layout bg="bg-peak">
    <Seo title="Anfahrt & Kontakt" />
    <div className="grid lg:grid-cols-2 boxed gap-12 md:gap-24 generic-page pt-32">
      <div data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500">
        <h1 className="lg:!text-5xl ">Kontaktiere uns</h1>
        <p>
          Wir telefonieren gerne. Wir schreiben gerne.
          <br />
          Und wir sprechen gerne persönlich mit Ihnen über Ihren Traumwagen.
        </p>
        <p>
          <a href="call:+43 5373 20 555">+43 5373 20 555 75</a>
          <br />
          <a href="mailto:info@peakmotors.at">info@peakmotors.at</a>
        </p>
        <ContactForm />
      </div>
      <div data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" data-sal-duration="500" className="w-full h-96 xl:h-full">
        {/* <iframe title='Standorte' className="relative block w-full h-96 xl:h-full object-contain" src="https://snazzymaps.com/embed/366566" /> */}
        <a href="https://goo.gl/maps/fYH5MV3WggnJdarU8" rel="noreferrer" target="_blank">
          <StaticImage
            quality={100}
            alt="Standorte"
            className="relative block w-full h-96 xl:h-full object-contain"
            placeholder="blurred"
            width={460}
            height={740}
            src="../assets/images/map.png"
          />
        </a>
      </div>
    </div>
  </Layout>
);

export default ContactPage;
